<template>
<v-app>
  <body>
    <v-container>
      <v-container class="white-bg mt-48px">
        <v-row>
          <v-col>
            <v-row justify="center">
              <h4 class="navy-text">Achieve Studio</h4>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>
      <v-lazy
        :options="{
          threshold: .5
        }"
        min-height="200"
      >
        <v-container class="ct-mw">
          <div v-if="!courseList">
            <v-row>
              <v-col v-for="i in 4" :key="i" cols="12" sm="6">
                <v-skeleton-loader
                transition="scale-transition"
                type="card"
                :loading="loading"
              >
              </v-skeleton-loader>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col
                v-for="(course, index) in courseList"
                :key="index"
                cols="12" sm="6"
              >
                <v-card v-if="course && course.active"
                  transition="scale-transition"
                >
                  <v-card-actions>
                    <v-img v-if="course.display"
                      :src="course.display"
                    ></v-img>
                    <v-responsive v-else :aspect-ratio="16/9" class="mb-4">
                      <iframe
                        :src="course.item[0].link"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                        allowFullScreen
                        :tag="course.item[0].tag"
                      >
                      </iframe>
                    </v-responsive>
                  </v-card-actions>
                  <v-card-title>
                    {{course.name}}
                  </v-card-title>
                  <v-card-subtitle>
                    จำนวน {{course.item.length}} คลิป
                  </v-card-subtitle>
                  <v-card-text v-if="course.description">
                    {{course.description}}
                  </v-card-text>
                  <v-card-actions>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          class="base-text subtitle-1"
                        >
                          เนื้อหา
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list>
                            <v-list-item-group>
                              <v-list-item
                                v-for="(vdo, index) in course.item"
                                :key="index"
                                @click="showVideo(vdo, index, course.item)"
                              >
                                <v-list-item-icon>
                                  <v-icon>play_circle_outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>EP{{index + 1}}: {{vdo.name}}</v-list-item-title>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-lazy>
    </v-container>
  </body>
  <v-overlay
    v-model="show"
    fullscreen
  >
    <v-container>
      <v-row justify="end">
        <v-icon class="pr-4" @click="close">close</v-icon>
      </v-row>
      <v-row>
        <v-col
          v-if="amountVDO > 1"
          align-self="center"
          class="text-center pa-0 pa-md-4"
          cols="1">
          <v-icon
            @click="previous"
            :disabled="currIndex === 0"
            x-large
            class="pa-0 pa-md-4"
          >navigate_before</v-icon>
        </v-col>
        <v-col  class="mb-4 vdo-display">
          <v-responsive :aspect-ratio="16/9">
            <iframe
              class="justify-center"
              :src="currVideo.link"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
              :tag="currVideo.tag"
            >
            </iframe>
          </v-responsive>
        </v-col>
        <v-col
          v-if="amountVDO > 1"
          align-self="center"
          class="text-center pa-0 pa-md-4" cols="1">
          <v-icon
            @click="next"
            :disabled="currIndex >= amountVDO - 1"
            x-large
          >navigate_next</v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-overlay>
</v-app>
</template>

<script>
import studioCourse from '../../firebase/firestore/studio';

export default {
  name: 'studio',
  data() {
    return {
      courseList: null,
      show: false,
      currVideo: {},
      currIndex: 0,
      playList: [],
      amountVDO: 0,
      loading: true,
    };
  },
  async mounted() {
    this.courseList = await studioCourse.getCourses();
    this.loading = false;
  },
  methods: {
    showVideo(vdo, index, list) {
      this.show = true;
      this.currVideo = vdo;
      this.currIndex = index;
      this.playList = list;
      this.amountVDO = list.length;
    },
    close() {
      this.show = false;
      this.currVideo = {};
      this.currIndex = 0;
      this.playList = [];
    },
    next() {
      console.log('click');
      if (this.currIndex < this.playList.length) {
        this.currIndex += 1;
        this.currVideo = this.playList[this.currIndex];
      }
    },
    previous() {
      if (this.currIndex > 0) {
        this.currIndex -= 1;
        this.currVideo = this.playList[this.currIndex];
      }
    },
  },
};

</script>

<style lang="sass" scoped>
.v-overlay__content
  width: 100% !important

.vdo-display
  @media (min-aspect-ratio: 12/7)
    max-width: 85% !important
    max-hieght: 85% !important
</style>
