import firebase from '../index';

const studio = firebase.firestore().collection('studio');

const getCourses = async () => {
  const snapshot = await studio.get();
  return snapshot.docs.map(doc => doc.data());
};


export default {
  getCourses,
};
